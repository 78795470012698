var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"picker-zitilist"},[_c('SpFilterForm',{attrs:{"model":_vm.formData,"size":"small"},on:{"onSearch":_vm.onSearch,"onReset":_vm.onSearch}},[_c('SpFilterFormItem',{attrs:{"prop":"keywords"}},[_c('el-input',{attrs:{"placeholder":"输入自提点名称、地址关键词"},model:{value:(_vm.formData.keywords),callback:function ($$v) {_vm.$set(_vm.formData, "keywords", $$v)},expression:"formData.keywords"}})],1)],1),_c('SpFinder',{ref:"finder",attrs:{"url":"/pickuplocation/list","fixed-row-action":true,"setting":{
      columns: [
        { name: '自提点名称', key: 'name' },
        {
          name: '地址',
          render: (h, { row }) =>
            h('span', {}, `${row.province}${row.city}${row.area}${row.address}`)
        },
        { name: '联系电话', key: 'contract_phone' },
        { name: '绑定店铺', key: 'rel_distributor_name' }
      ]
    },"hooks":{
      beforeSearch: _vm.beforeSearch,
      afterSearch: _vm.afterSearch
    }},on:{"selection-change":_vm.onSelectionChange}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }