var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"picker-planting"},[_c('SpFinder',{ref:"finder",attrs:{"other-config":{
      'max-height': 416,
      'header-cell-class-name': _vm.cellClass
    },"url":"/article/management","fixed-row-action":true,"setting":{
      columns: [
        { name: 'ID', key: 'article_id', width: 80 },
        { name: '软文标题', key: 'title' }
      ]
    },"hooks":{
      beforeSearch: _vm.beforeSearch,
      afterSearch: _vm.afterSearch
    }},on:{"select":_vm.onSelect,"selection-change":_vm.onSelectionChange}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }