var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'wgt-film': true,
    'padded': _vm.value.padded
  }},[(_vm.value.title || _vm.value.subtitle)?_c('div',{staticClass:"wgt-hd"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.value.title))]),_c('span',{staticClass:"sub-title"},[_vm._v(_vm._s(_vm.value.subtitle))])]):_vm._e(),_c('div',{staticClass:"wgt-bd"},[_c('video-player',{attrs:{"options":{
        aspectRatio: _vm.aspectRatio,
        sources: [
          {
            type: 'video/mp4',
            src: _vm.sourceUrl
          }
        ],
        notSupportedMessage: '添加视频源',
        suppressNotSupportedError: true,
        controlBar: false
      }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }