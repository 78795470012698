var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"picker-goods-params"},[_c('SpFinder',{ref:"finder",attrs:{"other-config":{
      'max-height': 460,
      'header-cell-class-name': _vm.cellClass
    },"url":"/goods/attributes","show-pager-text":"已选中：${n}","fixed-row-action":true,"setting":_vm.setting,"hooks":{
      beforeSearch: _vm.beforeSearch,
      afterSearch: _vm.afterSearch
    },"reserve-selection":"","row-key":"attribute_id"},on:{"select":_vm.onSelect,"selection-change":_vm.onSelectionChange}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }