var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-dialog',{attrs:{"title":"选择店铺","visible":_vm.showModal,"width":"600px","append-to-body":"","before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.showModal=$event}}},[_c('div',{staticClass:"content-bottom-padded"},[_c('el-input',{staticStyle:{"width":"240px"},attrs:{"placeholder":"店铺名称","clearable":""},on:{"clear":_vm.handleClear},model:{value:(_vm.params.name),callback:function ($$v) {_vm.$set(_vm.params, "name", $$v)},expression:"params.name"}},[_c('el-button',{attrs:{"slot":"append","icon":"el-icon-search"},on:{"click":_vm.handleKeySearch},slot:"append"})],1),_c('el-cascader',{attrs:{"placeholder":"根据地区筛选","options":_vm.regions,"filterable":"","clearable":"","props":{ checkStrictly: true }},on:{"change":_vm.handleRegionSearch},model:{value:(_vm.checkedRegions),callback:function ($$v) {_vm.checkedRegions=$$v},expression:"checkedRegions"}})],1),_c('div',{staticClass:"infinite-list-wrapper"},[_c('ul',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.fetch),expression:"fetch"}],staticClass:"store-list",attrs:{"infinite-scroll-disabled":"disabled"}},_vm._l((_vm.list),function(item){return _c('li',{key:item.id,staticClass:"store-list-item",class:_vm.multiple
              ? _vm.current && _vm.current.findIndex((cur) => cur.id === item.id) !== -1
                ? 'active'
                : ''
              : _vm.current.id === item.id
                ? 'active'
                : '',on:{"click":function($event){return _vm.handleItemClick(item)}}},[_c('div',{staticClass:"store-name"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"store-address"},[_vm._v(" "+_vm._s(item.address)+" ")])])}),0),(_vm.loading)?_c('div',{staticClass:"content-padded content-center"},[_c('i',{staticClass:"el-icon-loading"}),_vm._v(" 加载中... ")]):_vm._e(),(_vm.noMore)?_c('div',{staticClass:"content-padded content-center muted"},[_vm._v(" 没有更多了 ")]):_vm._e()]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.handleClose}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleConfirm}},[_vm._v("确 定")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }