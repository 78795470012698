var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'wgt-coupon': true,
    'padded': _vm.value.padded
  }},[(_vm.value.title || _vm.value.subtitle)?_c('div',{staticClass:"wgt-hd"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.value.title))]),_c('span',{staticClass:"sub-title"},[_vm._v(_vm._s(_vm.value.subtitle))])]):_vm._e(),_c('div',{class:[
      'wgt-bd',
      `coupon-style-${
        _vm.value.data.length + _vm.value.voucher_package.length <= 2
          ? _vm.value.data.length + _vm.value.voucher_package.length
          : 3
      }`
    ]},[_vm._l((_vm.value.data),function(item,index){return _c('div',{key:`wgt-coupon-item__${index}`,class:[
        'wgt-coupon-item',
        {
          'has-img': item.imgUrl
        }
      ],style:(_vm.getCouponStyle(item))},[(!item.imgUrl)?[_c('div',{staticClass:"coupon-bd"},[(item.type == 'cash')?_c('div',{staticClass:"coupon-amount"},[_c('span',{staticClass:"symbol"},[_vm._v("¥")]),_c('span',{staticClass:"value"},[_vm._v(_vm._s(item.amount / 100))])]):_vm._e(),(item.type == 'discount')?_c('div',{staticClass:"coupon-amount"},[_c('span',{staticClass:"value"},[_vm._v(_vm._s(item.amount))]),_c('span',{staticClass:"symbol"},[_vm._v("折")])]):_vm._e(),(item.type == 'new_gift')?_c('div',{staticClass:"coupon-amount"},[_c('span',{staticClass:"value"},[_vm._v(_vm._s(item.amount / 100))]),_c('span',{staticClass:"symbol"},[_vm._v("元")])]):_vm._e(),_c('div',{staticClass:"coupon-desc"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(item.desc))])])]),_vm._m(0,true)]:_vm._e()],2)}),_vm._l((_vm.value.voucher_package),function(item,index){return _c('div',{key:`wgt-coupon-package-item__${index}`,class:['wgt-coupon-item'],style:(_vm.getCouponStyle(item))},[(!item.imgUrl)?[_c('div',{staticClass:"coupon-bd"},[_vm._m(1,true),_c('div',{staticClass:"coupon-desc"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(item.desc))])])]),_vm._m(2,true)]:_vm._e()],2)})],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"coupon-ft"},[_c('div',{staticClass:"btn"},[_vm._v("领取")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"coupon-amount"},[_c('span',{staticClass:"package-value"},[_vm._v("券包")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"coupon-ft"},[_c('div',{staticClass:"btn"},[_vm._v("领取")])])
}]

export { render, staticRenderFns }