var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"picker-shop"},[_c('SpFilterForm',{attrs:{"model":_vm.formData,"size":"small"},on:{"onSearch":_vm.onSearch,"onReset":_vm.onSearch}},[_c('SpFilterFormItem',{attrs:{"prop":"region"}},[_c('el-cascader',{ref:"region",attrs:{"filterable":"","clearable":"","placeholder":"选择地区筛选店铺","options":_vm.district},model:{value:(_vm.formData.region),callback:function ($$v) {_vm.$set(_vm.formData, "region", $$v)},expression:"formData.region"}})],1),_c('SpFilterFormItem',{attrs:{"prop":"keywords"}},[_c('el-input',{attrs:{"placeholder":"请输入店铺名称"},model:{value:(_vm.formData.keywords),callback:function ($$v) {_vm.$set(_vm.formData, "keywords", $$v)},expression:"formData.keywords"}})],1)],1),_c('SpFinder',{ref:"finder",class:['shop-finder', { 'no-multiple': !_vm.multiple }],attrs:{"other-config":{
      'max-height': 460,
      'header-cell-class-name': _vm.cellClass
    },"url":"/distributors","fixed-row-action":true,"setting":_vm.setting,"hooks":{
      beforeSearch: _vm.beforeSearch,
      afterSearch: _vm.afterSearch
    }},on:{"select":_vm.onSelect,"selection-change":_vm.onSelectionChange}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }