var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'wgt-goods-grid-tab': true,
    'padded': _vm.data.padded
  }},[(_vm.data.title || _vm.data.subtitle)?_c('div',{staticClass:"wgt-hd"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.data.title))]),_c('span',{staticClass:"sub-title"},[_vm._v(_vm._s(_vm.data.subtitle))])]):_vm._e(),_c('div',{staticClass:"wgt-bd"},[_c('div',{staticClass:"tabs-header"},_vm._l((_vm.data.list),function(item,index){return _c('div',{key:index,staticClass:"tab-item",class:[
          'tab-item',
          {
            'checked': _vm.checked == index
          }
        ],on:{"click":function($event){return _vm.handleClick(index)}}},[_vm._v(" "+_vm._s(item.tabTitle)+" ")])}),0),_c('div',{staticClass:"tab-content"},[_c('div',{staticClass:"lf-box"},_vm._l((_vm.leftGoodsList),function(item,index){return _c('div',{key:`left-item__${index}`,staticClass:"item-box"},[_c('SpImage',{attrs:{"src":item.imgUrl}}),_c('div',{staticClass:"item-info",style:({ 'padding-top': _vm.value.brand ? '30px' : '4px' })},[(_vm.value.brand)?_c('div',{staticClass:"brand-logo"},[_c('SpImage',{attrs:{"src":item.brand,"width":60,"height":60,"circle":""}})],1):_vm._e(),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.title))]),(_vm.value.showPrice)?_c('div',{staticClass:"price"},[_c('SpPrice',{staticClass:"item-price",attrs:{"value":item.price / 100,"size":15}}),_c('SpPrice',{staticClass:"line-price",attrs:{"value":item.market_price / 100,"size":13,"line-through":""}})],1):_vm._e()])],1)}),0),_c('div',{staticClass:"rg-box"},_vm._l((_vm.rightGoodsList),function(item,index){return _c('div',{key:`right-item__${index}`,staticClass:"item-box"},[_c('SpImage',{attrs:{"src":item.imgUrl}}),_c('div',{staticClass:"item-info",style:({ 'padding-top': _vm.value.brand ? '30px' : '4px' })},[(_vm.value.brand)?_c('div',{staticClass:"brand-logo"},[_c('SpImage',{attrs:{"src":item.brand,"width":60,"height":60,"circle":""}})],1):_vm._e(),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.title))]),(_vm.value.showPrice)?_c('div',{staticClass:"price"},[_c('SpPrice',{staticClass:"item-price",attrs:{"value":item.price / 100,"size":15}}),_c('SpPrice',{staticClass:"line-price",attrs:{"value":item.market_price / 100,"size":13,"line-through":""}})],1):_vm._e()])],1)}),0)]),_vm._m(0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"goods-more"},[_c('p',{staticClass:"more-btn"},[_vm._v("查看更多")])])
}]

export { render, staticRenderFns }