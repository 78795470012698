var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"picker-wxapp"},[_c('SpFilterForm',{attrs:{"model":_vm.formData,"size":"small"},on:{"onSearch":_vm.onSearch,"onReset":_vm.onSearch}},[_c('SpFilterFormItem',{attrs:{"prop":"region"}},[_c('el-select',{attrs:{"clearable":"","placeholder":"请选择小程序"},model:{value:(_vm.formData.approve_status),callback:function ($$v) {_vm.$set(_vm.formData, "approve_status", $$v)},expression:"formData.approve_status"}},_vm._l((_vm.wxappList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.title,"size":"mini","value":item.value}})}),1)],1)],1),_c('SpFinder',{ref:"finder",class:[{ 'no-multiple': !_vm.multiple }],attrs:{"other-config":{
      'max-height': 460
    },"url":"/wxexternalconfigroutes/list","fixed-row-action":true,"setting":{
      columns: [
        { name: '小程序名称', key: 'app_name' },
        { name: 'appid', key: 'app_id' },
        { name: '页面名称', key: 'route_name' },
        { name: '页面路径', key: 'route_info' }
      ]
    },"hooks":{
      beforeSearch: _vm.beforeSearch,
      afterSearch: _vm.afterSearch
    }},on:{"select":_vm.onSelect,"selection-change":_vm.onSelectionChange}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }