import { render, staticRenderFns } from "./attr-item.vue?vue&type=template&id=586474f2&scoped=true"
import script from "./attr-item.vue?vue&type=script&lang=js"
export * from "./attr-item.vue?vue&type=script&lang=js"
import style0 from "./attr-item.vue?vue&type=style&index=0&id=586474f2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "586474f2",
  null
  
)

export default component.exports