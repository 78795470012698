var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'wgt-nearby-shop': true,
    'padded': _vm.value.padded
  }},[(_vm.value.title || _vm.value.subtitle)?_c('div',{staticClass:"wgt-hd"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.value.title))]),_c('span',{staticClass:"sub-title"},[_vm._v(_vm._s(_vm.value.subtitle))])]):_vm._e(),_c('div',{staticClass:"wgt-bd"},[_c('div',{staticClass:"nearby-box"},[_c('div',{staticClass:"tag-list"},_vm._l((_vm.value.seletedTags),function(item,index){return _c('span',{key:item.tag_id,class:[
            'tag-item',
            {
              'active': index == _vm.activeIndex
            }
          ],on:{"click":function($event){return _vm.handleClickTag(item, index)}}},[_vm._v(_vm._s(item.tag_name))])}),0),_c('div',{staticClass:"shop-list"},[(_vm.shopList.length <= 0)?_c('div',{staticClass:"default-shop"},[_c('SpImage',{attrs:{"src":_vm.defaultShopImg,"width":160}}),_c('div',[_vm._v("更多商家接入中，敬请期待")])],1):_c('div',{staticClass:"shop-content"},_vm._l((_vm.shopList),function(item){return _c('div',{key:item.distributor_id,staticClass:"shop-item"},[_c('div',{staticClass:"shop-banner",style:({
                backgroundImage: `url(${item.banner || _vm.defaultShopBanner})`
              })},[_c('div',{staticClass:"logo-wrap"},[_c('SpImage',{staticClass:"shop-logo",attrs:{"circle":23,"src":item.logo || _vm.defaultShopLogo,"width":46,"height":46}})],1)]),_c('div',{staticClass:"shop-name"},[_vm._v(" "+_vm._s(item.name)+" ")]),(_vm.value.show_coupon)?_c('div',{staticClass:"coupon-list"},_vm._l((item.discountCardList),function(coupon,index){return _c('div',{key:`coupon-item__${index}`,staticClass:"coupon-item"},[_vm._v(" "+_vm._s(coupon.title)+" ")])}),0):_vm._e()])}),0)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }