var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"picker-coupon"},[_c('SpFinder',{ref:"finder",attrs:{"other-config":{
      'max-height': 460,
      'header-cell-class-name': _vm.cellClass
    },"url":"/discountcard/list","fixed-row-action":true,"setting":{
      columns: [
        {
          name: '卡券类型',
          key: 'card_type',
          width: '100px',
          render: (h, { row }) =>
            h(
              'el-tag',
              {
                props: {
                  size: 'mini'
                }
              },
              _vm.cardTypeFormatter(row)
            )
        },
        { name: '卡券名称', key: 'title' },
        {
          name: '卡券有效期',
          formatter: (value, { takeEffect, begin_time, end_time }, col) => {
            if (takeEffect) {
              return takeEffect
            } else {
              return _vm.getCardValidate(begin_time, end_time)
            }
          }
        },
        {
          name: '可领取库存',
          formatter: (value, { quantity, get_num }, col) => {
            if (quantity > get_num) {
              return quantity - get_num
            } else {
              return 0
            }
          },
          width: '100px'
        },
        { name: '领取量', key: 'get_num', width: '80px' },
        { name: '使用量', key: 'use_num', width: '80px' }
      ]
    },"hooks":{
      beforeSearch: _vm.beforeSearch,
      afterSearch: _vm.afterSearch
    }},on:{"select":_vm.onSelect,"selection-change":_vm.onSelectionChange}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }